.button
  display: inline-flex
  justify-content: center
  align-items: center
  height: 40px
  padding: 0 24px
  color: $dark
  font-size: 1em
  font-weight: 400
  border-radius: 40px
  transition: all ease-out .25s
  background-color: $white
  outline: none !important
  &.dark
    color: $white !important
    background-color: $dark
    height: 48px
    padding: 0 32px
  &.outline
    color: $dark !important
    border: 2px solid $dark
    &.int
      color: $white !important
      background-color: $dark
      margin-left: 16px !important
      &:hover
        background-color: $blue !important
      +v-sm
        margin-left: auto !important
    +v-sm
      margin-left: auto
      margin-right: auto
    &.cookies
      min-width: 220px
      margin-left: auto !important
      margin-right: auto
  &:hover
    color: $white !important
    background-color: $blue
    border: 2px solid $blue
